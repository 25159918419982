.contact-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: 1270px) {
        margin-bottom: 200px;
    }

    &__title {
        font-family: 'DM Sans';
        font-weight: medium;
        font-size: 42px;
        color: white;
        margin-top: 50px;
        margin-bottom: 36px;
        width: 420px;
        
        text-align: center;
        @media (max-width: 768px) {
            font-size: 32px;
            text-align: center;
        }
    }

    &__text {
        font-weight: 400;
        font-family: 'DM Sans';
        font-size: 22px;
        color: rgba(255, 255, 255, 0.6);
        width: 60%;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 20px;
            width: 80%;
            text-align: center;
        }
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 450px;
        height: auto;

        @media (max-width: 768px) {
            width: 90%;
            height: auto;
        }

    }

    &__input {
        width: 100%;
        background-color: #111111;
        border: 1px rgba(235, 188, 6, .2) solid;
        height: 64px;
        border-radius: 25px;
        margin-top: 15px;
        padding: 10px 30px;
        color: white;
        font-family: 'DM Sans';
        &:first-child{
            margin-top: 30px;
        }
        &:nth-child(3){
            margin-bottom: 40px;
        }
        &:invalid {
            border: 1px red solid;
        }

        &--text-area {
            height: 128px;
            padding: 20px 25px;
            resize: none
        }
    }

    &__button {
        color: white;
        margin-top: 46px;
        background: linear-gradient(212.7deg, #009FE3 -4.12%, #4EBA9D 68.86%);
        padding: 17px 46px 18px;
        border-radius: 80px;
        font-family: 'DM Sans';
        font-weight: medium;
        font-size: 20px;
        border: none;

        @media (max-width: 768px) {
            margin-top: 50px;
            width: 100%;
        }

    }

    &--error {

        @keyframes shake-horizontal {

            0%,
            100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            10%,
            30%,
            50%,
            70% {
                -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
            }

            20%,
            40%,
            60% {
                -webkit-transform: translateX(10px);
                transform: translateX(10px);
            }

            80% {
                -webkit-transform: translateX(8px);
                transform: translateX(8px);
            }

            90% {
                -webkit-transform: translateX(-8px);
                transform: translateX(-8px);
            }
        }

        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    }

    &__alert {
        width: 200px;
        height: 200px;
        position: absolute;


        &--success {
            animation: rotate-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2 alternate both;

            @keyframes rotate-in-center {
                0% {
                    -webkit-transform: rotate(-360deg);
                    transform: rotate(-360deg);
                    opacity: 0;
                }

                100% {
                    -webkit-transform: rotate(0);
                    transform: rotate(0);
                    opacity: 1;
                }
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-image: url(./assets/checked.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

    }
}