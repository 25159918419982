@import '../../../../colors';

.service-hero {
    width: 100%;
    background-color: $black;
    height: auto;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 1280px) {
        padding-bottom: 50px;
    }

    &__content {
        display: flex;
        height: auto;
        flex-direction: row;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        color: white;
        width: 80%;
        font-family: DM Sans;
        margin-top: 10px;

        @media (max-width: 1280px) {
            flex-direction: column;
            align-items: center;
        }

    }

    .service-hero__main {
        width: 600px;
        padding-top: 36px;
        font-style: medium;

        @media (max-width: 1280px) {
            padding-top: 55px;
            width: 300px;
            text-align: center;
        }
    }

    .service-hero__title--main {
        font-size: 32px;

        @media (max-width: 1280px) {
            text-align: left;
            font-size: 24px;
        }
    }

    .service-hero__text {
        margin-top: 15px;
        font-size: 22px;
        color: rgba(255, 255, 255, 1);

        @media (max-width: 1280px) {
            font-size: 16px;
            text-align: left;
            margin-top: 20px;
        }
    }

    &__image-container {
        width: 730px;
        position: relative;

        @media (max-width: 1280px) {
            width: 300px;
            padding-top: 40px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
    }
}