@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lexend+Deca:wght@100;300&display=swap');
@import url('https://api.fontshare.com/css?f[]=clash-display@200,300,400,500,600,700,1&display=swap');
@import 'colors';
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.App{
    overflow: hidden;
    background-color: $black;
}
.button-white-rounded{
    background-color: white;
    padding: 17px 46px;
    border-radius: 30px;
    border:none;
    outline: none;
    font-size: 18px;
    font-family: 'Clash Display';
    font-weight: 500;
}
.button-transparent-white{
    background-color: transparent;
    padding: 17px 46px;
    border-radius: 30px;
    border:none;
    outline: none;
    font-size: 18px;
    font-family: 'Clash Display';
    font-weight: 500;
    color:white;
    display:flex;
    width: 200px;
    justify-content: space-between;
}