.footer {
    color: white;

    .content {
        max-width: 1440px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px auto;
        height: 100%;

        @media (max-width: 1320px) {
            margin: 50px 0;
            max-width: 100%;
            flex-direction: column;
            justify-content: center;
            gap: 50px;
        }

        .image {
            width: 100px;

        }
    }
}