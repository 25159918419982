.clients {
    &-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 100px;
    }

    &-title {
        margin-bottom: 100px;
        margin-top: 170px;
        color:white;
        font-family: 'DM Sans';
        text-align: center ;
    }

    &-item {
        width: 210px;
        height: 180px;

        @media (max-width: 1320px) {
            width: 165px;
            height: 145px;
        }

        & img {
            width: 100%;
            height: 100%;
        }
    }
}