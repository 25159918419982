@import '../../../../colors';

.header {
    width: 100%;
    color: white;
    font-family: 'DM Sans', sans-serif;
    margin-top: 40px;
    position: absolute;

    @media (max-width: 1320px) {
        background-color: $black;
        position: fixed;
        margin-top: 0;
        padding-top: 30px;
        z-index: 9;
        padding-bottom: 10px;
    }

    .header__content {
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;

        @media (max-width: 1320px) {
            justify-content: space-between;
        }

        & .header__logo {
            width: 73px;
            height: 43px;
            z-index: 3;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        & .header__nav {
            margin-left: 50px;

            &-list {
                gap: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                & .header__nav-item {
                    list-style: none;

                    cursor: pointer;

                }
            }

            @media (max-width: 1320px) {
                margin-left: 0;
                padding-top: 100px;
                position: absolute;
                width: 100vw;
                left: 0;
                top: 0;
                overflow: hidden;
                height: calc(60vh + 100px);
                background-color: $black;
                z-index: 1;
                display: none;

                &.open {
                    display: block;
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    height: 100%;

                    & .header__nav-item {
                        list-style: none;
                        font-family: Lexend Deca;
                        font-size: 24px;
                        font-weight: 600;
                        color: $gray;
                    }
                }
            }

        }

        & .header__hamburger-button {
            width: 34px;
            height: 28px;
            background-image: url('../../../../../public/assets/images/svg/burger.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            z-index: 9;

            @media (min-width: 1320px) {
                display: none;
            }
        }



        & .header__action {
            margin-left: 18px;

            @media (max-width: 1320px) {
                display: block;
                margin-left: auto;
                margin-right: 30px;
            }

            .header__language_select {
                border: none;
                outline: none;
                background-color: transparent;
                color: white;
                background-color: rgb(21, 27, 41);
                font-size: 14px;
                appearance: initial;

                //when select expand
                &:hover {
                    color: white;
                    border: none;
                    outline: none;
                }
            }
        }
    }
}