.faq {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: 1270px) {
        margin-top: 0;
    }

    &__title {
        font-family: DM Sans;
        font-size: 42px;
        font-weight: bold;
        text-align: center;
        color: white;
        margin-top: 50px;
        margin-bottom: 50px;

        @media (max-width: 768px) {
            font-size: 32px;
            text-align: center;
        }
    }

    &__container {
        color: white;
        width: 800px;
        margin: 30px;
    }

    &__subtitle {
        font-size: 18px;
        line-height: 25px;
        font-family: DM Sans;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & img {
            transition: all .5s;
        }
    }

    &__text {
        height: 0;
        width: 95%;
        overflow: hidden;
        color: rgba(255, 255, 255, .6);
        transition: height .5s;
        font-family: DM Sans;
        &-link {
            text-decoration: none;
            padding-bottom: 1px;
            border-bottom: 1px solid #EBbc06;

            &:hover {
                color: #f2f2f2;
            }
        }
    }

    &__item {
        margin-bottom: 15px;
        cursor: pointer;
        border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
        padding-bottom: 20px;

        & .faq__subtitle {
            & img {
                transform: scale(1) rotateZ(0deg);

                @media (max-width: 768px) {
                    transform: scale(0.8) rotateZ(0deg);
                }
            }
        }

        &--open {
            & .faq__subtitle {
                color: white;

                & img {

                    transform: scale(1.1) rotateZ(180deg);

                    @media (max-width: 768px) {
                        transform: scale(0.9) rotateZ(180deg);
                    }
                }
            }

            & .faq__text {
                height: auto;

            }

        }
    }
}