@import '../../../../colors';

.banner {
    height: 630px;
    background-color: $black;
    margin-bottom: 50px;
    @media (max-width: 1280px) {
        height: 700px;
    }

    &__container {
        background-image: url(./assets/background.png);
        max-width: 1333px;
        height: 100%;
        margin: 0 auto;
        padding-top: 100px;
        padding-left: 95px;
        background-repeat: no-repeat;

        @media (max-width: 1280px) {
            background-image: url(./assets/background-mobile.png);
            width: 345px;
            height: 700px;
            padding-top: 36px;
            padding-left: 0;
        }
    }

    &__title {
        width: 710px;
        font-family: 'DM Sans';
        font-weight:bold;
        color: white;
        @media (max-width: 1280px) {
            width: 260px;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
        }
        
        .main {
            font-size: 54px;
            font-weight: 500;
            @media (max-width: 1280px) {
                font-size: 30px;
            }
        }
    }
    
    &__text {
        font-family: 'DM Sans';
        width: 500px;
        font-size: 16px;
        font-weight: 400;
        font-weight: regular;
        color:white;
        @media (max-width: 1280px) {
            width: 260px;
            margin-left: auto;
            margin-right: auto;
            text-align: left;
            margin-top:12px;
        }
    }

   
}