@import '../../../../colors';

.hero {
    width: 100%;
    background-color: $black;
    height: 725px;
    background: url('./assets/main-hero-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 30px;

    @media (max-width: 1280px) {
        height: 100vh;
        background: url('./assets/main-hero-bg-mobile.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 bottom;
    }

    &__content {
        display: flex;
        height: 700px;
        flex-direction: row;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        color: white;
        width: 80%;
        font-family: DM Sans;

        @media (max-width: 1280px) {
            flex-direction: column;
            align-items: center;
        }

    }

    .hero__main {
        width: 600px;
        font-style: medium;
        height: fit-content;
        margin-top: 300px;

        @media (max-width: 1280px) {
            padding-top: 55px;
            width: 300px;
            text-align: center;
            margin-top: 150px;
        }
    }

    .hero__title--upper {
        position: relative;
        font-size: 52px;
        font-weight: 500;

        &-badge {
            background-color: #009FE3;
            border-radius: 20px;
            position: absolute;
            top: -10px;
            right: -40px;
            transform: rotate(15deg);
            font-size: 20px;
            padding: 4px 10px;

            @media (max-width: 1280px) {
                top: -30px;
                right: -20px;
            }
        }

        @media (max-width: 1280px) {
            font-size: 32px;
            font-weight: 400;
            text-align: left;
        }


    }

    .hero__title--main {
        font-size: 52px;
        font-weight: 500;

        @media (max-width: 1280px) {
            text-align: left;
            font-weight: 500;
            font-size: 28px;
        }
    }

    .hero__text {
        font-size: 22px;
        color: rgba(255, 255, 255, 0.7);
        border-left: 6px #009FE3 solid;
        padding-left: 22px;

        @media (max-width: 1280px) {
            font-size: 16px;
            text-align: left;
            padding-left: 10px;
            margin-top: 20px;
        }
    }

    &__image-container {
        width: 700px;
        position: relative;
        top: -100px;
        left: -100px;
        display: none;

        @media (max-width: 1280px) {
            width: 300px;
            padding-top: 40px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
    }
}