.social-media-icons {
    margin-left: auto;
    display: flex;
    gap: 16px;


    &.hidden-sm {
        @media (max-width: 1320px) {
            display: none;
        }
    }
    @media (max-width: 1320px) {
        display: flex;
        margin: 0 auto;
    }

    &-item {
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: contain;

        &--discord {
            background-image: url('../../../../../public/assets/images/svg/discord.svg');
        }

        &--medium {
            background-image: url('../../../../../public/assets/images/svg/medium.svg');
        }

        &--twitter {
            background-image: url('../../../../../public/assets/images/svg/twitter.svg');
        }

        &--instagram {
            background-image: url('../../../../../public/assets/images/svg/instagram.svg');
        }

        &--telegram {
            background-image: url('../../../../../public/assets/images/svg/telegram.svg');
        }
    }

}