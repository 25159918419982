.t-process {
    height: 1150px;
    width: 1440px;
    margin: 0 auto;
    margin-top: 200px;
    font-family: DM Sans;

    @media (max-width: 1280px) {
        width: 350px;
        height: 2620px;
        padding-top: 40px;
    }

    & .title {
        text-align: center;
        color: white;
        font-weight: 600;
        margin-bottom: 70px;
        font-size: 42px;
    }

    & .process-way {
        width: 1445px;
        height: 800px;
        background-image: url(./assets/background.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;

        @media (max-width: 1280px) {
            background-image: url(./assets/background-mobile.svg);
            background-position: 15px -1650px;
            background-size: 8%;
            width: 345px;
            height: 100%;

        }

        & .content {
            width: 1030px;
            margin: 0 auto;
            display: grid;
            row-gap: 70px;
            column-gap: 30px;
            grid-template-columns: 1fr 1fr 1fr;

            @media (max-width: 1280px) {
                grid-template-columns: 1fr;
                row-gap: 20px;
            }

            .process {

                @media (max-width: 1280px) {
                    width: 345px;
                }

                & .dot {
                    width: 55px;
                    height: 55px;
                    border: 10px white solid;
                    border-radius: 50%;
                    background-color: rgb(13, 164, 228);
                    margin-bottom: 35px;
                }

                & .title {
                    font-size: 24px;
                    text-align: start;
                    font-weight: 600;
                    width: 100%;
                    margin-bottom: 8px;

                    @media (max-width: 1280px) {
                        font-size: 20px;
                        position: relative;
                        top:-90px;
                        left:70px;
                        width: 300px;
                    }
                }

                & .text {
                    text-align: start;
                    font-size: 14px;
                    font-weight: 300;
                    color: white;
                    max-width: 350px;
                    min-width: 290px;
                    @media (max-width: 1280px) {
                        position: relative;
                        top:-80px;
                        left:70px;
                        max-width: 250px;
                        min-width: 0px;
                        height: auto;
                    }

                }
            }

        }
    }
}