.smart-contracts {
    color: white;
    margin-top:70px;
    .title {
        font-size: 42px;
        font-weight: bold;
        font-family: DM Sans;
        text-align: center;
        margin-bottom: 70px;
    }

    .content {
        position: relative;
        margin: 0 auto;
        width: 1270px;
        height: 540px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        column-gap: 90px;
        row-gap: 76px;
        @media (max-width: 1270px) {
            width: 345px;
            height: auto;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            gap:25px;
        }
        .card {
            border-radius: 40px;
            width: 540px;
            height: 170px;
            display: flex;
            background: radial-gradient(circle, rgba(37, 206, 171, 0.25)10%, rgba(0, 159, 227, 0.2) 70%);
            backdrop-filter: blur(10px);
            border: 3px red solid;
            border-color: #1F4250;
            padding: 0 20px;
            text-align: start;
            align-items: center;

            @media (max-width: 1270px) {
                width: 345px;
                height: auto;
                padding: 10px;
                border-radius: 30px;
                border-width: 1.5px;
            }

            .icon {
                width: 120px;
                @media (max-width: 1270px) {
                    width: 60px;
                }
                align-self: flex-start;
            }

            .card-content {
                .card-title {
                    font-size: 24px;
                    font-weight: bold;
                    font-family: DM Sans;
                    margin-bottom: 3px;
                    @media (max-width: 1270px) {
                        font-size: 18px;
                    }
                }

                .card-text {
                    font-weight: 400;
                    font-size: 16px;
                    font-family: DM Sans;
                    @media (max-width: 1270px) {
                        font-size: 14px;
                        font-weight:regular;
                    }
                }
            }

        }

        .arrow-icon {
            position: absolute;
            &.arrow-1{
                left: 600px;
                top: 100px;
            }
            &.arrow-2{
                left: 600px;
                top: 410px;
                transform: rotate(180deg);
            }
            &.arrow-3{
                left: 950px;
                top: 260px;
                transform: rotate(90deg);
            }
        }
    }
}