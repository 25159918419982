.nft-projects {
    height: 824px;
    & .content {
        margin: 0 auto;
        height: 85%;
        background-image: url(./assets/background.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        @media (max-width: 1270px) {
            height: auto;
            padding-top: 0;
            background-size: 160%;
            background-position: -250px -60px;
        }
        
        & .list {
            width: 1130px;
            height: 260px;
            color: white;
            font-size: 22px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, 1fr);
            align-items: center;
            justify-items: center;
            column-gap: 300px;
            @media (max-width: 1270px) {
                width: 350px;
                height: auto;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                gap:5px;
                margin-top:200px;
            }

            & .list-item {
                margin-bottom: 12px;
                text-align: start;
                width: 100%;
                font-family: DM Sans;
                font-size: 22px;
                font-weight: bold;
                list-style: none;
                display: flex;
                @media (max-width: 1270px) {
                    font-size: 20px;
                }
                & .disk {
                    display: block;
                    width: 22px;
                    height: 22px;
                    background-color: #009FE3;
                    border-radius: 20px;
                    text-align: left;
                    padding-top: 1px;
                    padding-left: 11px;
                    margin-right: 25px;
                }

                & .text {
                    display: block;
                }
            }
        }
    }
}